import { Box } from '@mantine/core';
import parse from 'html-react-parser';

export const HTMLPreview = ({ html, autosize }: { html: string; autosize?: boolean }) => (
  <Box
    style={{
      borderRadius: '4px',
      overflowY: 'scroll',
    }}
    miw={autosize ? '100%' : 500}
    p={0}
    h={autosize ? '100%' : 240}
    bg="white"
  >
    {parse(html)}
  </Box>
);
