import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';

import { useUser } from 'app/UserContext';
import { MESSAGE_PRIORITIES } from 'constants/globalConstants';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import { AdditionalRequestConfig } from 'hooks-api/useEvolveApi';
import {
  PageFetcher,
  useWrappedDelete,
  useWrappedGet,
  useWrappedPaginatedGet,
  useWrappedPatch,
} from 'hooks-api/useWrappedApiCall';
import { TypeOfUser } from 'types/types-api';

import { Message, MessageDetails, MessagesReq } from './types';

type MessagesContextType = {
  getMessages: PageFetcher<Message, any>;
  loadingMessages: boolean;
  deleteMessage: (config?: AdditionalRequestConfig<any> | undefined, throwOnMoreRecent?: boolean) => Promise<unknown>;
  deleting: boolean;
  updateMessage: (body: unknown, config?: AdditionalRequestConfig<unknown> | undefined) => Promise<unknown>;
  updating: boolean;
  markAllRead: (body: unknown, config?: AdditionalRequestConfig<unknown> | undefined) => Promise<unknown>;
  markingRead: boolean;
  getUserTypes: PageFetcher<TypeOfUser, any>;
  getMessageDetails: (
    config?: AdditionalRequestConfig<any> | undefined,
    throwOnMoreRecent?: boolean,
  ) => Promise<MessageDetails>;
  gettingMessageDetails: boolean;
  undismissedHighPriorityMessages: Message[];
  setUndismissedHighPriorityMessages: (value: Message[]) => void;
};

type Props = {
  children: ReactNode;
};

export const MessagesContext = createContext<MessagesContextType | undefined>(undefined);

export const MessagesProvider = ({ children }: Props) => {
  const [undismissedHighPriorityMessages, setUndismissedHighPriorityMessages] = useState<Message[]>([]);
  const { fetchPage: getUserTypes } = useWrappedPaginatedGet<TypeOfUser>('admin/type', {
    lazy: true,
  });
  const { fetchPage: getMessages, loading: loadingMessages } = useWrappedPaginatedGet<Message, MessagesReq>(
    'message/message',
    {
      lazy: true,
    },
  );
  const { apiCall: deleteMessage, loading: deleting } = useWrappedDelete('message/message');
  const { apiCall: updateMessage, loading: updating } = useWrappedPatch('message/message');
  const { apiCall: markAllRead, loading: markingRead } = useWrappedPatch('message/message/readAll');
  const { apiCall: getMessageDetails, loading: gettingMessageDetails } =
    useWrappedGet<MessageDetails>('message/message');

  const { user } = useUser();

  useEffect(() => {
    if (isNotNil(user)) {
      getAllDataFromFetcher(getMessages, {
        params: {
          messagePriorityId: MESSAGE_PRIORITIES.HIGH,
          status: 'Active',
        },
      }).then(setUndismissedHighPriorityMessages);
    }
  }, [getMessages, user]);

  const messagesObj = useMemo(
    () => ({
      getMessages,
      loadingMessages,
      deleteMessage,
      deleting,
      updateMessage,
      updating,
      markAllRead,
      markingRead,
      getMessageDetails,
      gettingMessageDetails,
      getUserTypes,
      undismissedHighPriorityMessages,
      setUndismissedHighPriorityMessages,
    }),
    [
      deleteMessage,
      deleting,
      getMessageDetails,
      getMessages,
      getUserTypes,
      gettingMessageDetails,
      loadingMessages,
      markAllRead,
      markingRead,
      undismissedHighPriorityMessages,
      setUndismissedHighPriorityMessages,
      updateMessage,
      updating,
    ],
  );
  return <MessagesContext.Provider value={messagesObj}>{children}</MessagesContext.Provider>;
};

export const useMessages = () => useGeneralContext(MessagesContext, 'Messages');
