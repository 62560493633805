import { useEffect } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';

import { AdminRoutes } from 'modules/Admin/AdminRoutes';
import { AuthenticationRoutes } from 'modules/Authentication/AuthenticationRoutes';
import { InvitationExpired } from 'modules/Authentication/InvitationExpired';
import DataAnalyticsRoutes from 'modules/DataAnalytics/DataAnalyticsRoutes';
import DesignRoutes from 'modules/Design/DesignRoutes';
import DrawingStatusController from 'modules/Design/DrawingStatus/DrawingStatusController';
import ElectricalRoutes from 'modules/Electrical/ElectricalRoutes';
import FieldRoutes from 'modules/Field/FieldRoutes';
import ManagementRoutes from 'modules/Management/ManagementRoutes';
import MaterialRoutes from 'modules/Materials/MaterialRoutes';
import MechanicalRoutes from 'modules/Mechanical/MechanicalRoutes';
import ShopRoutes from 'modules/Shop/ShopRoutes';
import { EModuleType, MODULE_URL_MAPPING } from 'types/module';

import SyncRoutes from '../modules/Sync/SyncRoutes';
import { MessageCenterPage } from './MessageCenter/MessageCenterPage';
import { PageNotFound } from './PageNotFound';
import { PrivateWrapper } from './PrivateWrapper';
import QRRedirect from './QRRedirect';
import { ResetPasswordLandingPage } from './ResetPasswordLandingPage';

export const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    const customEvent = new CustomEvent('ContainerUI:location', {
      detail: { location },
    });
    document.dispatchEvent(customEvent);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<PrivateWrapper />}>
        <Route path={`${MODULE_URL_MAPPING[EModuleType.ADMIN]}/*`} element={<AdminRoutes />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.MATERIALS]}/*`} element={<MaterialRoutes />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.SHOP]}/*`} element={<ShopRoutes />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.DESIGN]}/*`} element={<DesignRoutes />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.DATA_ANALYTICS]}/*`} element={<DataAnalyticsRoutes />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.ELECTRICAL]}/*`} element={<ElectricalRoutes />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.MECHANICAL]}/*`} element={<MechanicalRoutes />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.FIELD]}/*`} element={<FieldRoutes />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.MANAGEMENT]}/*`} element={<ManagementRoutes />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.MESSAGE]}/`} element={<MessageCenterPage />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.MESSAGE]}/:messageId`} element={<MessageCenterPage />} />
        <Route path={`${MODULE_URL_MAPPING[EModuleType.SYNC]}/*`} element={<SyncRoutes />} />
      </Route>

      <Route path="auth/*" element={<AuthenticationRoutes />} />
      <Route path="invitation-expired" element={<InvitationExpired />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="reset-password-landing" element={<ResetPasswordLandingPage />} />
      <Route path="no-licenses" element={<ResetPasswordLandingPage noLicense />} />
      <Route path="resource/:id" element={<DrawingStatusController />} />
      <Route path="qr/:id" element={<QRRedirect />} />
    </Routes>
  );
};
