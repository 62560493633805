import { useCallback, useEffect, useState } from 'react';

import { Drawer, useMantineTheme, Text, Flex, Button } from '@mantine/core';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { extractTextFromHTML } from 'helpers/stringFunctions';

import { useMessages } from './MessageCenter/MessagesContext';
import { MessageDetails } from './MessageCenter/types';

export const HighPriorityMessageBanner = () => {
  const [highPriorityMessage, setHighPriorityMessage] = useState<MessageDetails | null>(null);
  const [currentlyViewing, setCurrentlyViewing] = useState(0);
  const { undismissedHighPriorityMessages, setUndismissedHighPriorityMessages, getMessageDetails, updateMessage } =
    useMessages();
  const theme = useMantineTheme();

  const navigate = useNavigate();

  useEffect(() => {
    if (undismissedHighPriorityMessages.length > 0) {
      getMessageDetails({ url: `message/message/${undismissedHighPriorityMessages[currentlyViewing].messageId}` }).then(
        setHighPriorityMessage,
      );
    }
  }, [getMessageDetails, undismissedHighPriorityMessages, currentlyViewing]);

  const dimissMessage = useCallback(() => {
    updateMessage({ status: 'Dismiss' }, { url: `message/message/${highPriorityMessage?.messageId}` }).then(() => {
      if (currentlyViewing < undismissedHighPriorityMessages.length - 1) {
        setCurrentlyViewing((prev) => prev + 1);
      } else {
        setUndismissedHighPriorityMessages([]);
      }
    });
  }, [
    currentlyViewing,
    highPriorityMessage,
    setUndismissedHighPriorityMessages,
    undismissedHighPriorityMessages,
    updateMessage,
  ]);

  const dismissAll = useCallback(() => {
    updateMessage({}, { url: 'message/message/dismissall' }).then(() => {
      setUndismissedHighPriorityMessages([]);
    });
  }, [setUndismissedHighPriorityMessages, updateMessage]);

  return (
    <Drawer
      position="bottom"
      opened={undismissedHighPriorityMessages.length > currentlyViewing}
      onClose={dimissMessage}
      size={300}
      closeOnClickOutside={false}
      color="red"
      overlayProps={{ color: theme.primaryColor }}
    >
      <div style={{ padding: '0 20px 0 20px' }}>
        {highPriorityMessage && (
          <>
            <Flex justify="space-between">
              <Text size="xl" weight="bold">
                {highPriorityMessage.subject}
              </Text>
              {undismissedHighPriorityMessages.length > 1 && (
                <Text>
                  {currentlyViewing + 1} of {undismissedHighPriorityMessages.length}
                </Text>
              )}
            </Flex>
            <Text size="sm" color={theme.primaryColor} mb="md">
              {format(parseISO(`${highPriorityMessage?.createdOn}Z`), 'MMMM dd, yyyy')}
            </Text>
          </>
        )}
        <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} w="100%" mb={50}>
          {extractTextFromHTML(highPriorityMessage?.body).replace('&apos;', "'")}
        </Text>
        <Flex justify="flex-end" gap="sm" align="center">
          <Button variant="outline" onClick={dimissMessage}>
            Dimiss
          </Button>
          {undismissedHighPriorityMessages.length > 1 && (
            <Button variant="outline" onClick={dismissAll}>
              Dimiss All
            </Button>
          )}
          <Button
            onClick={() => {
              dimissMessage();
              navigate(`/message/${highPriorityMessage?.messageId}`);
            }}
          >
            View in message center
          </Button>
        </Flex>
      </div>
    </Drawer>
  );
};
